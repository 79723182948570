@import "../../../scss/variables/all";

.clear-button {
    color: #A1AFBA;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    padding: 0 5px;
    span.fa-times-circle {
        margin-right: 0;
    }
}
