@import "../../../scss/variables/colors";

@import "./Pagination";
@import "./PageSize";
@import "./Filter";
@import "./TableTools";
@import "./FiltersList";
@import "./Search";
@import "./AddFilter";

.ms-table {
    background: $medispend-white;
    padding: 24px;
    border: 1px solid $medispend-gray-100;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    position: relative;

    .ms-table-wrapper {
        //TODO: create custom scroll to be consistent in all OS
        //overflow-y: scroll;
    }

    table {
        width: 100%;

        tr {
            border-bottom: 1px solid $medispend-gray-200;

            td, th {
                padding: 8px 5px;
                word-break: break-word;
            }

            .column-header-sortable {
                cursor: pointer;
            }
        }

        thead {
            tr {
                th {
                    font-weight: 500;
                }
            }
        }

        tbody {
            tr {
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}
