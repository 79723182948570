@import "../../../scss/variables/all";


.date-field {
    height: 34px;
    input, input:focus {
        padding: 4px 8px;
        font-size: 14px;
    }
    .ms-sort-clear {
        color: $medispend-gray-500;
        padding-left: 5px;
        align-items: center;
        padding-right: 5px;
        background: $medispend-white;
        cursor: pointer;
        margin-right: 2px;
        font-size: 13px;
        position: absolute;
        top: 8px;
        right: 0;
    }
}
