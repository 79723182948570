@import "../../../scss/variables/all";

.ms-select {

    &-indent {
        margin-right: 6px;
    }

    &-content {
        display: flex;
        align-items: center;
    }

    .dropdown .dropdown-menu .dropdown-item {
        padding-left: 24px;
        &:active,&:hover,&:focus {
            background: $medispend-gray-200;
            color: $medispend-gray-900;
            font-weight: 500;
        }
    }

    .dropdown-menu {
        padding-right: 3px;
    }

    .select-toggle {
        display: flex;
        width: 240px;
        background: $medispend-gray-100;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: $medispend-gray-900;
        padding: 8px 16px;

        .ms-icon {
            font-size: 16px;
            position: relative;
            top: 2px;
            left: -2px;
        }

        &::after {
            margin-left: auto;
        }
    }

    &-checkbox-menu {
        pointer-events: none;
        display: flex;
        align-items: center;

        .ms-checkbox {
            margin-right: 8px;
        }
    }

    .btn-primary:hover,
    .btn-primary:focus,
    .show > .btn-primary.select-toggle:focus,
    .btn-primary:active {
        box-shadow: none;
        border: 0;
        background-color: $medispend-gray-800;
    }

    .ms-search-icon {
        color: $medispend-gray-400;
    }
    .input-group-text {
        background: transparent;
        border: none;
        color: $medispend-gray-500;
        font-size: 13px;
    }

    .input-group .control-buttons {
        background: transparent;
        border: none;
        color: $medispend-gray-500;
        border-radius: 5px;
        font-size: 13px;
        display: flex;
    }

    .ms-input.form-control {
        font-size: 13px;
        line-height: 16px;
        border:none;
        padding: 0;
        height: 24px;
    }
    .form-control {
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }
}
.ms-dropdown {
    margin-right: 24px;
    margin-left: 25px;
    margin-bottom: 5px;
    border: 1px solid $medispend-gray-400;
    outline: none;
    border-radius: 5px;
    &:focus-within {
        border: none;
        outline: none;
    }
}
.input-group:focus-within {
    outline: none;
    border: 1px solid $medispend-green;
    border-radius: 5px;
}

.ms-select-button {
    .ms-caret-down {
        margin-left: 5px;
        height: 8px;
        position: relative;
    }
    .ms-caret-down.is-menu-open {
        transform: rotate(180deg);
        top: 2px;
    }
    .control-buttons {
        display: flex;
        justify-content: space-between;
        margin-left: 7px;
        .ms-caret-down {
            margin-right: 0;
            margin-left: 0;
        }
    }
}
.ms-select.has-error {
    border: 1px solid $medispend-red;
    border-radius: 5px;
}

.ms-select-button.active-grey-800 {
    color: $medispend-white;
    background-color: $medispend-gray-800;
}
