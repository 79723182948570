@import "../../../scss/variables/all";

.modal-backdrop.back-drop-fixed {
    position: fixed;
}

.modal:has(.ms-modal__prompt) {
    z-index: 1061;
}

.ms-modal-header {
    padding: 16px 24px;
    border-bottom: 1px solid $medispend-gray-200;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    .ms-modal-title {
        position: static;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;

        /* gray/900 */
        color: $medispend-gray-900;

        &--button {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }
        &:has(.ms-modal-title--button) {
            width: 100%;
            padding-right: 8px;
        }
    }

    .ms-modal-close {
        cursor: pointer;
        color: $medispend-gray-500;
    }

    .ms-modal-close:hover {
        color: $medispend-gray-500;
        opacity: 0.9;
    }
}

.ms-model-body {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
}
