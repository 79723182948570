@import "../../../../../lib/common/src/scss/variables/all";

.ms-footer {
    align-self: flex-start;
    width: 100%;
    height: max-content;
    padding: 5px;
    border-top: 1px solid $medispend-grey-border;
    color: $medispend-gray-900;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;

    &-content {
        & > a {
            text-decoration: none;
            color: currentColor;

            &:hover {
                color: inherit;
                text-decoration: underline;
            }
        }
    }
}
