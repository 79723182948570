@import "../../../scss/utils/clearfix";
@import "../../../scss/variables/colors";

.ms-table-filter {
    @extend .clearfix;
    padding-bottom: 10px;
    border-bottom: 1px solid $medispend-gray-200;

    .ms-table-page-size {
        float: right;
    }

    .ms-table-filter-top {
        @extend .clearfix;
        padding-bottom: 18px;
        border-bottom: 1px solid $medispend-gray-200;

        .ms-table-page-size {
            float: right;
        }
    }

    .ms-table-choice-filter {
        clear: both;
        width: 100%;
        padding-top: 13px;

        .dropdown {
            display: inline-block;
            margin-right: 8px;

            button {
                padding: 8px 16px;
                font-size: 13px;
                color: $medispend-gray-900;
                border-radius: 20px;
                background: $medispend-gray-100;
                border: none;
            }

            .btn-check:focus + .btn-primary, .btn-primary:focus {
                box-shadow: none;
                background: $medispend-gray-800;
                color: #FFFFFF;
            }

            .dropdown-menu {
                font-size: 13px;
                color: $medispend-gray-900;
                border: 0;
                background: #FFFFFF;
                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
                border-radius: 5px;

                .dropdown-item {
                    padding: 8px 24px;
                }
            }
        }
    }
}


