@import "../../../scss/variables/colors";


.ms-table-filter-add {
    cursor: pointer;
    .ms-icon {
        color: $medispend-green;
    }
    span {
        margin-left: 5px;
        font-weight: 500;
    }
}
