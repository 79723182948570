@import "../../scss/components/form";

.form-input {
    border: 1px solid $medispend-gray-400;
    border-radius: 5px;
    padding: 5px 16px;
    font-size: 13px;
    line-height: 17px;
    color: $medispend-gray-900;
    width: 100%;
}
