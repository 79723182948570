@import "../../../scss/variables/all";

$sprite-workflow-path: "../../../../media/sprites/workflow/";
$svg-list: "approve", "assign", "close", "open", "return", "migrate";

@mixin svgPath($svgName) {
    &.#{$svgName} {
        background-image: url(#{$sprite-workflow-path}#{$svgName}.svg);
    }
}

.ms-icon.ms-icon-workflow {
    display: inline-block;
    width: 24px;
    height: 24px;

    @each $svgName in $svg-list {
        @include svgPath($svgName);
    }
}
