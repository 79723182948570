@import "../../../../../../../../lib/common/src/scss/variables/all";


.table-container {
    margin: 10px 0;
    .table-body-container {
        overflow-y: visible;
        overflow-x: scroll;

        .table-header {
            display: flex;
            gap: 16px;
            align-items: center;
            padding: 8px 0 8px 107px;
            border-bottom: none;

            h3 {
                font-size: 20px;
                margin-bottom: 0;
            }

            .policy-select {
                width: 150px;

                .ms-form-group {
                    margin-bottom: 0;

                    .ms-select {
                        .dropdown {
                            .ms-select-button, .dropdown-menu {
                                width: 150px
                            }
                        }
                    }
                }
            }
        }

        .table-body {
            th {
                border: 2px solid $medispend-gray-500;
            }

            .blank-col {
                min-width: 78px;
            }

            tfoot {
                td {
                    transition: all 0.2s ease;
                    height: 30px;
                    border: 2px solid $medispend-gray-400;

                    .add-row-button {
                        transition: all 0.2s ease;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 24px;
                        line-height: 28px;
                        height: 100%;
                        cursor: pointer;
                        color: $medispend-gray-700;
                    }

                    &:hover {
                        .add-row-button {
                            color: $medispend-gray-500;
                        }
                    }
                }
            }
        }
    }
}
