@import "../../lib/common/src/scss/variables/colors";

.rule-edit-container {
    padding: 16px 20px;
    h3 {
        font-size: 18px;
        margin-bottom: 16px;
    }
    .trigger-selector {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
    }
    .drd-container {
        margin-bottom: 20px;
    }
    .error-list-transition-enter, {
        opacity: 0;
        transform: scale(0.9);
    }
    .error-list-transition-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 200ms, transform 200ms;
    }
    .error-list-transition-exit {
        opacity: 1;
    }
    .error-list-transition-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 200ms, transform 200ms;
    }
}
