.modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    .show {
        opacity: unset;
    }
}

.modal {
    display: flex !important;

    .modal-dialog {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        margin: 0;

        position: absolute;
        width: 722px;
        max-width: unset;
        min-width: 480px;
        height: auto;
        left: 50%;
        transform: translate(-50%, 0) !important;
        right: auto;
        top: 40px;

        background: #FFFFFF;
        border-radius: 10px;

        .tab-buttons-container {
            position: absolute;
            top: -17px;
            left: 0;
            text-align: center;
            width: 100%;

            .tab-buttons {
                display: inline-block;
            }
        }

        .modal-content {
            border: none;

            .success {
                color: $medispend-green;
            }

            .modal-header {
                border: none;
                padding: 10px 20px;

                .close {
                    float: right;
                    font-size: 1.5rem;
                    font-weight: 700;
                    line-height: 1;
                    color: #000;
                    text-shadow: 0 1px 0 #fff;
                    opacity: .5;
                    background-color: transparent;
                    border: 0;
                    position: absolute;
                    top: 5px;
                    right: 5px;

                    &:hover {
                        color: $ms-colors-sec;
                    }
                }

            }
        }
    }

    .modal-dialog--wide {
        width: 90%;
    }

    &.has-tabs {
        .modal-body {
            padding-top: 35px;
            min-height: 100px;
        }
    }

    &.modal-full {
        .modal-dialog {
            width: 80%;

            .modal-body {
                min-height: 300px;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .modal {
        &.modal-full {
            .modal-dialog {
                width: 100%;
                margin: 10px;
            }
        }
    }
}
