@import "../variables/_all.scss";

html {
    -webkit-font-smoothing: antialiased;
}

body, .view-container {
    display: flex;
    flex-direction: column;
    font-family: Helvetica Neue,"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: $medispend-gray-900;
}

a {
    color: $medispend-green;
    text-decoration: none;
    &:hover {
        color: $medispend-green;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}


