@import "../../../scss/variables/all";

$sprite-apps-path: "../../../../media/sprites/apps/";

.ms-icon.ms-icon-apps {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: {
        position: 0 0;
        repeat: no-repeat;
        image: url($sprite-apps-path + "apps-sprites.svg");
    }
    &.br {
        background: {
            image: url($sprite-apps-path + "br.svg");
        }
    }
    &.cff {
        background: {
            image: url($sprite-apps-path + "cff.svg");
        }
    }
    &.global-admin {
        background: {
            image: url($sprite-apps-path + "global-admin.svg");
        }
    }
    &.workflow {
        background: {
            image: url($sprite-apps-path + "workflow.svg");
        }
    }
    &.contracts {
        background: {
            position: -112px -10px;
        };
    }
    &.engagements {
        background: {
            position: -146px -10px;
        };
    }
    &.fmv {
        background: {
            position: -10px -10px;
        };
    }
    &.insights {
        background: {
            position: -214px -10px;
        };
    }
    &.needs {
        background: {
            position: -44px -10px;
        };
    }
    &.payments {
        background: {
            position: -180px -10px;
        };
    }
    &.qualifications {
        background: {
            position: -78px -10px;
        };
    }

    &.white {
        &.contracts, &.engagements, &.fmv, &.insights, &.needs, &.payments, &.qualifications {
            background: {
                position-y: -78px;
            };
        }
    }
}

.active, .link:hover {
    .ms-icon.ms-icon-apps {
        &.contracts, &.engagements, &.fmv, &.insights, &.needs, &.payments, &.qualifications {
            background: {
                position-y: -44px;
            };
        }
    }
}
