@import "../../scss/overrides/all";

.ms-form-select {
    .ms-button-title {
        font-size: 14px;
        color: #192024;
        font-weight: 500;
        line-height: 18px;
    }
    .ms-button {
        padding-top: 7px;
        padding-bottom: 7px;
    }
    .ms-button-title.is-placeholder {
        color: $medispend-gray-500;
    }
    .dropdown-item {
        font-size: 14px;
        &:active, &.active {
            background: $medispend-gray-200;
            color: $medispend-gray-900;
        }
    }
    .strategy-abosulte {
        .dropdown-menu {
            transform: none!important;
            &.show {
                inset: 40px auto auto 0!important;
            }
        }
    }
}
