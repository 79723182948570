@import "../../../../../lib/common/src/scss/variables/all";
@import "../../../../admin-common/src/scss/table";
$sprite-apps-path: "../../../../../lib/common/media/sprites/utils/";


.header-wrapper {
    .create-rule-button {
        height: 32px;
        padding: 0 15px;

        .create-rule-label-icon {
            margin-right: 5px;
            background-color: $medispend-white;
            -webkit-mask-image: url($sprite-apps-path + "copy.svg");
            mask-image: url($sprite-apps-path + "copy.svg");
            width: 15px;
            height: 15px;
        }
        .create-rule-label {
            margin-top: 3px;
        }
    }
}

.ms-table {
    .ms-table-tools.has-page-size-opts{
        .search-block {
            .ms-button.ms-select-button.btn.btn-grey-100 {
                padding: 8px 16px;
            }
            .ms-icon.ms-icon-utils.ms-caret-down.caret-down {
                display: none;
            }
        }
    }
    table {
        margin-top: 15px;
        td {
            height: 48px;
        }
        .ms-table-header {
            tr {
                th {
                    font-weight: 500;
                }
                .client-header {
                    min-width: 150px;
                    max-width: 200px;
                }

                .rule-type-header {
                    min-width: 150px;
                    max-width: 200px;
                }

                .rule-name-header {
                    min-width: 300px;
                    max-width: 320px;
                }

                .status-header, .process-header, .effective-date-header,
                .expiration-date-header, .rule-id-header {
                    width: 140px;
                }
            }
        }
    }
}
