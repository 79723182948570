@import "../variables/all";

.notification-type-error {
    border-top: 2px solid $medispend-red;
    background-color: $ms-notification-error;
    border-radius: initial;
    .notification-button {
        background: $medispend-red;
    }
}

.notification-type-success {
    border-top: 2px solid $medispend-green;
    background-color: $ms-notification-success;
    border-radius: initial;
}

.notification-type-warning {
    border-top: 2px solid $medispend-orange;
    background-color: $ms-notification-warning;
    border-radius: initial;
    .notification-button {
        background: $medispend-orange;
    }
}

.notification-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    .notification-message {
        font-size: 13px;
        color: rgb(65, 47, 47);
    }
    .notification-button {
        border-radius: 2px;
        padding: 6px 20px;
        font-weight: bold;
        margin: 10px 0 0;
        border: 0;
        color: rgb(255, 255, 255);
        font-size: 13px;
    }
}
