.form-group {
  position: relative;
  label {
    cursor: pointer;
  }
  &.has-error {
    .form-control {
      color: #a94442;
      background-color: #f2dede;
      border-color: #a94442;
    }
    .error {
      color: #a94442;
      text-align: left;
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 10px;
      line-height: 1;
    }
  }
  &.form-group-double {
    &.has-error {
      .error {
        &.left {
          right: 52%;
        }
      }
    }
    .form-control {
      display: block;
      float: left;
      width: 49%;
      &:last-child {
        float: right;
      }
    }
  }
}

.form-control, .input-group-addon, .btn {
  border-radius: 3px;
}

.form-control {
  border-radius: 3px;
  height: auto;
  padding: 10px 12px;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: none;

  &:focus {
    border-color: $medispend-orange;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(237, 125, 35, 0.6);
  }
}

.error-message {
    color: $medispend-red;
    margin-top: 5px;
}

.error-message.error-message-soft {
    color: $medispend-orange;
}
