@import "../../../scss/variables/all";

.ms-layout {
    .ms-header {
        position: fixed;
        z-index: 1020;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0 1px 0 #D8E2E8;

        .navbar {
            margin-bottom: 0;
            height: $header-height;
            padding: 0 20px;
            &.navbar-default {
                background: #ffffff;
                border-radius: 0;
                border-width: 0 0 1px 0;
            }

            .container-fluid {
                padding-left: 0;
            }

            .navbar-nav {
                .nav-link {
                    padding-right: 1.5rem;
                    padding-top: 6px;
                    margin-top: 6px;
                    color: $medispend-gray-900;
                }
            }

            .ms-header-left-nav {
                .nav-link {
                    padding-left: 0.25rem;
                    padding-top: 3px;
                }
            }

            .navbar-brand {
                .nav-link {
                    margin-top: 4px;
                }
            }
        }

        .logo-container {
            background: $medispend-green;
            width: 255px;
            height: 50px;
            transition: all 0.2s ease-in-out;

            &:after {
                content: "";
                height: 1px;
                width: 255px;
                position: absolute;
                left: 0;
                bottom: -1px;
                background: #333F48;
                transition: all 0.2s ease-in-out;
            }

            .navbar-brand {
                padding: 0;
                margin: 13px 0 0 15px;
                transition: all 0.2s ease-in-out;
            }

            .compact-view-trigger {
                display: inline-block;
                color: $ms-nav-sec-color;
                font-size: 14px;
                margin: 17px 0 0 13px;

                &:hover {
                    color: $ms-nav-sec-hover;
                }
            }
        }

        #ms-module-dropdown, #ms-user-menu {
            border: 0;
            border-radius: 0;
            height: 50px;

            .ms-icon {
                position: relative;
                top: 1px;
                font-size: 20px;
            }
        }

        .ms-header-top-nav {
            .badge {
                background: $medispend-green;
                position: relative;
                top: -8px;
                min-width: 25px;
                display: inline-block;
            }
        }
    }

    &.compact-view {
        .ms-header {
            .logo-container {
                width: 55px;
                overflow: hidden;

                .navbar-brand {
                    width: 22px;
                }

                &:after {
                    width: 55px;
                }

                .compact-view-trigger {
                    display: none;
                }
            }
        }
    }

    #ms-header-products-list, #ms-header-user-toggle {
        border-radius: 8px;
        padding: 6px;
        &:hover {
            background: $medispend-gray-200;
        }
    }

    #ms-header-user-toggle {
        padding-top: 7px;
    }

    .user-toggle-block {
        padding-left:  10px;
    }

    #ms-header-products-list {
        margin-top: 9px;
        margin-right: 10px;
    }
    #ms-header-products-list, #ms-header-language-toggle, #ms-header-help-toggle {
        &.dropdown-toggle::after {
            display: none;
        }
    }
    #ms-header-language-toggle {
        padding-top: 7px;
    }
    #ms-header-user-toggle {
        .avatar {
            display: inline-block;
            background: $medispend-green;
            color: #FFFFFF;
            padding: 1px 3px;
            border-radius: 100%;
        }
    }
    .dropdown.show {
        #ms-header-products-list {
            background: $medispend-gray-200;
        }
        .dropdown-item {
            .ms-header-lang-flag {
                position: relative;
                top: -1px;
                margin-right: 6px;
            }
        }
    }

    .ms-header-right-nav {
        margin-left: auto;
        .dropdown-menu {
            right: 0;
            left: auto;
        }
    }
    .ms-header-left-nav {
        margin-right: auto;
    }

    .ms-header-help-about {
        position: relative;
        left: -2px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &-label {
            margin-left: 3px;
        }

        &-icon {
            font-size: 15px;
        }
    }
}
