@import "../../../../../common/src/scss/variables/all";

.version-modal {

    &-header {
        background-color: rgba($medispend-blue-bg, 0.4);
    }

    &-body {
        &:not(:last-child) {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid $medispend-grey-border;
        }

        & div {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            column-gap: 10px;
        }
    }

    &-key,
    &-value {
        display: inline-block;
        font-weight: bold;
    }

    &-key {
        width: 20%;
        text-transform: uppercase;
        padding: 0 5px;
        font-size: 14px;
    }

    &-value {
        font-family: 'Courier New', Courier, monospace;
        font-size: 16px;
        color: $medispend-gray-900;
        padding-left: 10px;
        padding-right: 100px;
        position: relative;
        left: -20px;
    }

    &-header {
        color: $medispend-green;
    }

    &-footer {
        width: 100%;
        height: 100%;
        background-color: rgba($medispend-green, 0.8);
    }
}
