@import "../../../../../../../../lib/common/src/scss/variables/all";


.rule-row {
    height: 30px;

    .add-col {
        .remove-row-button {
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            padding-left: 8px;
            padding-right: 10px;
            font-size: 16px;
            line-height: 28px;
            height: 100%;
            font-weight: 400;
            .ellipsis-dropdown, .ellipsis-dropdown.show {
                &.dropup {
                    button {
                        background-color: $medispend-white;
                        margin: 0;
                        padding: 0;
                        color: $medispend-gray-500;

                        &:hover, &:focus {
                            background-color: $medispend-white;
                            color: $medispend-gray-500;
                            box-shadow: none;
                        }
                        &::after {
                            display: none;
                        }
                        .row-options {
                            cursor: pointer;
                        }
                    }
                    .dropdown-menu.show {
                        transform: translate3d(16px, 15px, 0px) !important;
                    }
                }
            }
            span {
                font-size: 16px;
                color: $medispend-gray-700;
                cursor: pointer;
                &:hover {
                    color: $medispend-gray-500;
                }
            }
            .counter {
                margin-left: auto;
            }
        }

        min-width: 78px;
        border-left: 2px solid $medispend-gray-400;
        border-bottom: 2px solid $medispend-gray-400;
    }

    .rule-cell {
        input {
            border: none;
            width: 100%;
            text-align: center;
            &:focus {
                outline: none;
            }
        }
        .ms-button.btn .ms-button-title .dropdown-title {
            width: auto;
        }
        .cell-dropdown {
            button {
                width: 100%;
                .ms-button-title {
                    justify-content: center;
                    .ms-caret-down {
                        display: none;
                    }
                }
            }
        }
        min-width: 295px;
        border-left: 2px solid $medispend-gray-400;
        border-bottom: 2px solid $medispend-gray-400;
    }

    .blank-col-footer-last {
        min-width: 295px;
        width: 100%;
        border-left: 2px solid $medispend-gray-400;
        border-right: 2px solid $medispend-gray-400;
        border-bottom: 2px solid $medispend-gray-400;
        input {
            border: none;
            width: 100%;
            &:focus {
                outline: none;
            }
        }
    }
}
