@mixin main-area() {
    background: $medispend-white;
    padding: 24px;
    border: 1px solid $medispend-gray-100;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    position: relative;
}
