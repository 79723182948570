@import "../../../scss/variables/all";

@function darker($color, $tone) {
    $adjust: -16*$tone;
    @return adjust-color($color, $red: $adjust, $green: $adjust, $blue: $adjust);
}

.ms-button.btn-check:focus + .ms-button.btn,
.ms-button.btn:focus {
    box-shadow: none;
}

.ms-button.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px 15px;
    border-radius: 5px;
    color: $medispend-white;

    .ms-button-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        .dropdown-title {
            width: 85%;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: start;
        }
    }

    &:active {
        transform: scale(0.97);
    }

    &:hover {
        color: $medispend-white;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
        transform: none;
    }

    .indent {
        margin-left: 5px;
    }
}

.ms-button.sm {
    padding: 8px 16px;
    font-size: 13px;
}

.ms-button.lg {
    padding: 11px 26px;
    font-size: 14px;

    .indent {
        margin-left: 8px;
    }
}

.ms-button.wd {
    width: 250px;
}

.ms-button.md {
    height: 32px;
    padding: 8px 16px;
}

.ms-button.btn-green {
    background-color: $medispend-green;

    &:hover {
        background-color: darker($medispend-green, 1);
    }
}

.ms-button.btn-orange {
    background-color: $medispend-orange;

    &:hover {
        background-color: darker($medispend-orange, 1);
    }
}

.ms-button.btn-red {
    background-color: $medispend-red-dark;

    &:hover {
        background-color: darker($medispend-red-dark, 1);
    }
}

.ms-button.btn-white {
    background-color: $medispend-white;
    color: $medispend-gray-900;

    &:hover {
        background-color: $medispend-white;
        color: $medispend-gray-900;
    }
}

.ms-button.btn-yellow {
    background-color: $medispend-yellow-dark;

    &:hover {
        background-color: darker($medispend-yellow-dark, 1);
    }
}


.ms-button.btn-grey-100 {
    background-color: $medispend-gray-100;
    color: $medispend-gray-900;

    &:hover {
        background-color: darker($medispend-gray-100, 1);
        color: $medispend-gray-900;
    }
}

.ms-button.btn-grey-200 {
    background-color: $medispend-gray-200;
    color: $medispend-gray-900;

    &:hover {
        background-color: darker($medispend-gray-200, 1);
        color: $medispend-gray-900;
    }
}

.ms-button.btn-grey-800 {
    background-color: $medispend-gray-800;
    color: $medispend-white;

    &:hover {
        background-color: darker($medispend-gray-800, 3);
        color: $medispend-white;
    }
}

.ms-button.pill {
    border-radius: 15px;
}
