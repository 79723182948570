@import "../../../scss/components/form";

.ms-form-group {
    .textarea-wrapper {
        display: flex;
        flex-direction: column;

        .value {
            white-space: break-spaces;
            word-wrap: break-word;
        }
        .ms-textarea {
            min-height: 64px;
            border: 1px solid $medispend-gray-400;
            border-radius: 5px;
            padding: 8px 16px;
            font-size: 13px;
            line-height: 16px;
            color: $medispend-gray-900;
            width: 100%;
        }
        .ms-textarea.has-error {
            border: 1px solid $medispend-red;
        }
        .ms-textarea:focus {
            outline: none;
            border: 1px solid $medispend-green;
        }
        .character-counter {
            display: flex;
            justify-content: flex-end;
            font-size: 12px;
            line-height: 14px;
            color: $medispend-gray-500;
        }
        .error-message {
            margin-top: -9px
        }
    }
}

