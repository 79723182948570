@import "./apps";
@import "./utils";
@import "./workflow";

.ms-icon {
    display: inline-block;

    &.ms-type {
        &.ellipsis-v {
            font-size: 6px;

            .fa {
                display: block;
            }
        }
    }
}
