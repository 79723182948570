@import "../../../scss/variables/colors";

$ms-sidenav-active-bg: $medispend-gray-200;
$ms-sidenav-active-color: $medispend-gray-900;

@mixin ms-layout-compact-side-nav {
    left: -182px;

    .ms-layout-menu-container {
        margin-left: 182px;

        h2 {
            display: none;
            padding: 0;
        }

        .ms-layout-menu {
            .ms-layout-menu-item {
                text-align: center;
                margin: 0 13px 16px 13px;

                .link {
                    padding: 6px;

                    .ms-icon {
                        position: relative;
                        top: 2px;
                    }

                    .name {
                        display: none;
                    }
                }
            }


        }
    }

    .action-trigger {
        text-align: right;

        .name {
            display: none;
        }
    }

    .action-trigger-container {
        left: 30px;
    }
}

.ms-layout {
    .ms-layout-side-nav {
        position: fixed;
        z-index: 1019;
        top: 63px;
        left: 0;
        width: 250px;
        height: 100%;
        background: white;
        transition: all 0.2s ease-in-out;
        text-align: center;
        overflow: auto;
        box-shadow: 1px 0 0 #D8E2E8;

        .ms-layout-side-nav-top {
            padding-top: 15px;
        }

        .link {
            color: #42526e;
            outline: none !important;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            border-radius: 8px;

            &:hover {
                color: $ms-sidenav-active-color;
            }
        }

        .action-trigger {
            display: block;
            text-decoration: none;
            font-size: 20px;

            &.view-mode-trigger {
                margin-bottom: 20px;
            }
        }

        .action-trigger-container {
            transition: left 0.2s ease-in-out;
            position: fixed;
            left: 210px;
            bottom: 10px;
        }

        .ms-layout-menu-container {
            h2 {
                text-align: left;
                font-size: 16px;
                padding: 15px 15px 10px 15px;
                color: #ffffff;
                font-weight: normal;
                text-transform: uppercase;

                .previous-route {
                    float: right;
                    color: #888;
                    font-size: 14px;

                    &:hover {
                        color: #ffffff;
                    }
                }
            }

            .ms-layout-menu {
                padding: 0;
                position: relative;

                &:first-child {
                    margin-top: 16px;
                }

                .ms-layout-menu-item {
                    text-align: left;
                    list-style: none;
                    color: #ffffff;
                    display: block;
                    height: 40px;
                    margin: 0 8px 12px 8px;
                    
                    .link {
                        position: relative;
                        display: flex;
                        text-decoration: none;
                        padding: 12px 16px;
                        &:hover,
                        &:active {
                            text-decoration: none;
                            background: $ms-sidenav-active-bg;
                            color: $ms-sidenav-active-color;
                            font-weight: 500;
                        }

                        .ms-icon {
                            position: absolute;
                            top: 8px;

                            &+.name {
                                padding-left: 38px;
                            }
                            &.caret-down {
                                cursor: pointer;
                                margin-left: 16px;
                                height: 8px;
                                position: relative;
                                top: 4px;
                            }
                            &.caret-up {
                                transform: rotate(180deg);
                                top: 4px;
                            }
                        }

                        .name {
                            font-size: 14px;
                            position: relative;
                            display: inline-block;
                            width: 100%;
                        }
                    }

                    &.active {
                        .link {
                            background: $ms-sidenav-active-bg;
                            color: $ms-sidenav-active-color;
                            font-weight: 500;
                        }
                    }

                    .ms-layout-sub-menu-wrapper {
                        display: grid;
                        grid-template-rows: 0fr;
                        transition: grid-template-rows 500ms;
                        &.expanded {
                            grid-template-rows: 1fr;

                            
                        }
                        .ms-layout-sub-menu {
                            grid-row: 1 / span 2;
                            overflow: hidden;
                            .ms-layout-menu-item {
                                display: flex;
                                align-items: center;
                                .link {
                                    font-weight: 400;
                                    color: #42526e;
                                }
                                    &.active .link {
                                        font-weight: 500;
                                        color: $ms-sidenav-active-color;
                                    }

                                    &:hover .link {
                                        font-weight: 500;
                                        color: $ms-sidenav-active-color;
                                    }



                                .link {
                                    padding: 0;
                                    background: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }

        .ms-layout-side-nav-loader-container {
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.5);

            .ms-layout-side-nav-loader {
                position: relative;
                top: 35%;
            }
        }
    }

    &.ms-layout-compact-view {
        .ms-layout-side-nav {
            @include ms-layout-compact-side-nav();
        }
    }
}