@import "../../lib/common/src/scss/variables/colors";

.error-container {
    padding: 5px;
    color: $medispend-red;
    border: 1px solid $medispend-red;
    min-width: 200px;
    font-size: 13px;
    .clear {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        span {
            cursor: pointer;
            &:hover {
                color: $medispend-red-dark;
            }
        }
    }
    ul {
        margin-top: 2px;
        margin-bottom: 2px;
        padding-left: 15px;
    }
}
