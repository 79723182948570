@import "../../../scss/utils/clearfix";
@import "../../../scss/variables/colors";

.ms-table-tools {
    @extend .clearfix;
    padding-bottom: 16px;
    border-bottom: 1px solid $medispend-gray-200;
    .search-block {
        display: flex;
        float: left;
        align-items: center;
        justify-content: center;
        .form-control {
            padding: 0;
        }
    }
    #additional-tools {
        float: left;
    }
    .ms-table-tools-right {
        display: flex;
        justify-content: flex-end;
        .dropdown {
            @extend .ms-table-grey-dropdown;
            .dropdown-toggle {
                border-radius: 5px;
                font-weight: 500;
            }
        }
        .ms-table-filter-selected {
            @extend .ms-table-dropdown-selected;
        }
    }
    .ms-table-page-size {
        float: right;
        margin-left: 5px;
    }
    .ms-table-text-search {
        float: left;
        width: 326px;
        height: 34px;
        .input-group{
            height: 32px;
        }
        .form-control {
            border-radius: 5px;
            border: 1px solid $medispend-gray-400;
            font-size: 13px;
            line-height: 16px;
            border-left: 0;
            &:focus {
                box-shadow: none;
            }
        }
        .input-group-text {
            background: transparent;
            border-right: 0;
            color: $medispend-gray-500;
            font-size: 13px;
        }
    }
}
