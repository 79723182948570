.ms-table-control-type {
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
}

.ms-table-grey-dropdown {
    display: inline-block;
    position: relative;
    top: 2px;

    .btn {
        @extend .ms-table-control-type;
    }

    .dropdown-item {
        @extend .ms-table-control-type;
    }
}

.ms-table-dropdown-selected {
    .btn {
        background: $medispend-gray-800;
        color: $medispend-white;
        &:hover, &.active, &:focus, &:active {
            color: $medispend-white;
        }
    }
}
