@import "../../../scss/variables/all";

$sprite-utils-path: "../../../../media/sprites/utils/";

.ms-icon.ms-icon-utils {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: {
        position: 0 -10px;
        repeat: no-repeat;
        image: url($sprite-utils-path + "utils-sprites.svg");
    }
    &.white-column {
        background: {
            image: url($sprite-utils-path + "white-column.svg");
            position: center;
        }
        margin-right: 6px;
    }
    &.view-edit-button {
        background: {
            image: url($sprite-utils-path + "view-edit-button.svg");
            position: center;

        }
    }
    &.rotate90-deg {
        transform: rotate(90deg);
    }
    &.add {
        background-position-x: -140px;
    }

    &.approve {
        background-position-x: -322px;
    }

    &.apps {
    }

    &.back {
        background-position-x: -399px;
    }

    &.caret-down {
        background-position-x: -167px;
    }

    &.checkmark {
        background-position-x: -348px;
    }

    &.chevron {
    }

    &.clock {
        background-position-x: -36px;
    }

    &.column {
        background-position-x: -244px;
    }

    &.complete {
    }

    &.copy {
        background-position-x: -10px;
    }

    &.edit {
        background-position-x: -114px;
    }

    &.exit {
        background-position-x: -322px;
    }

    &.password {
        background-position-x: -244px;
    }

    &.print {
        background-position-x: -88px;
    }

    &.reassign {
        background-position-x: -269px;
    }

    &.row {
        background-position-x: -192px;
    }

    &.trash {
        background-position-x: -373px;
    }

    &.workflow {
        background-position-x: -62px;
    }
}

.ms-icon.ms-icon-utils.active {
    &.add,
    &.approve,
    &.apps,
    &.back,
    &.caret-down,
    &.checkmark,
    &.chevron,
    &.clock,
    &.column,
    &.complete,
    &.copy,
    &.edit,
    &.exit,
    &.password,
    &.print,
    &.reassign,
    &.row,
    &.trash,
    &.workflow {
        background: {
            position-y: -36px;
        }
    ;
    }
}

