@import "../../../../../lib/common/src/scss/variables/all";

.ellipsis-dropdown, .ellipsis-dropdown.show {
    display: flex;
    cursor: pointer;
    &.dropdown, &.dropstart, &.dropend {
        button {
            background-color: $medispend-white;
            color: $medispend-gray-500;
            padding: 0;
            margin: 0;

            &:hover, &:focus {
                background-color: $medispend-white;
                color: $medispend-gray-500;
                box-shadow: none;
            }
            &::after {
                display: none;
            }
            &::before {
                display: none;
            }
            .row-options {
                display: flex;
                cursor: pointer;
            }
        }
    }
}
