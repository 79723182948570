.ms-card {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    padding: 25px;
    border-radius: 3px;
    border: 1px solid #dddddd;
    background: #ffffff;
    max-width: 380px;

    h1 {
        margin: 0 0 10px 0;
        font-size: 1.6em;
        font-weight: normal;
    }

    .card-title {
        margin: -25px -25px 15px -25px;
        padding: 15px 25px;
        border-radius: $ms-bradius $ms-bradius 0 0;
        border-bottom: 1px solid $medispend-grey-border;
        font-size: 15px;
    }

    .card-desc {
        margin: 0 0 15px 0;
    }

    &.ms-card-secondary {
        text-align: center;
        padding: 10px;
        background: transparent;
        border: 1px solid rgba(14, 41, 57, 0.1);
        box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .5), 0 1px 0 hsla(0, 0%, 100%, 0.5);
        color: #8a8a8a;

        a {
            color: #8a8a8a;
            text-decoration: underline;

            &:hover, &:focus, &:active {
                color: $ms-colors-sec;
            }
        }
    }

    .ms-card-footer {
        margin: 25px -25px -25px -25px;
        padding: 15px 0;
        border-top: 1px solid $medispend-grey-border;
        background: #f8f8f8;
        border-radius: 0 0 3px 3px;
    }

    &.fluid {
        max-width: 100%;
    }
}
