@import "../../../scss/variables/all";


.ms-range-picker-container {
    position: relative;
    .ms-select-button {
        .ms-caret-down {
            margin-top: 2px;
            margin-left: 5px;
            color: $medispend-gray-800;
        }
    }
    .ms-select-button.btn-grey-800 {
        color: $medispend-white;
        background-color: $medispend-gray-800;
    }
    .ms-range-picker {
        position: absolute;
        left: auto;
        top: 40px;
        right: auto;
        min-width: 270px;
        background: $medispend-white;
        box-shadow: 0 4px 15px rgb(0 0 0 / 15%);
        border-radius: 5px;
        padding: 20px 15px 5px 15px;
        margin: 0;
        inset: 0 auto auto 0;
        transform: translate3d(0px, 36px, 0px);
        z-index: 1000;
    }

    .datepicker-fields {
        display: flex;
        align-items: baseline;
        column-gap: 5px;
    }

    .datepicker-footer {
        float: right;
        margin-bottom: 10px;
        margin-top: 15px;
        .cancel-button {
            margin-right: 5px;
        }
    }
}
