@import "../variables/colors";

.ms-form-group {
    margin-bottom: 16px;
    .form-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $medispend-gray-900;
        .required-sign {
            color: $medispend-red;
            margin-left: 2px;
            font-size: 13px;
            line-height: 16px;
        }
        &.has-error {
            color: $medispend-red;
            font-size: 14px;
        }
    }
    button {
        width: 100%;
    }
}

.w-40 {
    width: 40%;
}

.w-60 {
    width: 60%;
}

.w-45 {
    width: 45%;
}

.w-55 {
    width: 55%;
}

.form-column {
    input {
        display: block;
        width: 100%;
    }
}

.form-section {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: minmax(0, 1fr);

    &__empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $medispend-gray-100;
        padding: 32px 16px;
        gap: 8px;

        &--errors {
            border: 1px solid $medispend-red;
            border-radius: 5px;

            .form-section__empty--hint {
                color: $medispend-red;
            }
        }

        &--hint {
            color: $medispend-gray-500;
            font-size: 12px;
        }
    }
}

.dynamic-section {
    display: grid;
    grid-auto-flow: row dense;
    margin-top:16px;
}

.col-num-1 {
    grid-template-columns: minmax(0, 1fr);
}

.col-num-2 {
    grid-template-columns: repeat(2, 50%);
}

.col-num-3 {
    grid-template-columns: repeat(3, 33%);
}

.col-num-4 {
    grid-template-columns: repeat(3, 25%);
}

.col-num-5 {
    grid-template-columns: repeat(3, 20%);
}

.col-start-1 {
    grid-column-start: 1;
}

.col-start-2 {
    grid-column-start: 2;
}

.col-start-3 {
    grid-column-start: 3;
}

.col-start-4 {
    grid-column-start: 4;
}

.col-start-5 {
    grid-column-start: 5;
}

.col-end-1 {
    grid-column-end: 1;
}

.col-end-2 {
    grid-column-end: 2;
}

.col-end-3 {
    grid-column-end: 3;
}

.col-end-4 {
    grid-column-end: 4;
}

.col-end-5 {
    grid-column-end: 5;
}

.col-end-6 {
    grid-column-end: 6;
}

.col-start-4 {
    grid-column-start: 4;
}

.col-start-5 {
    grid-column-start: 5;
}

.error-message {
    color: $medispend-red;
    font-size: 12px;
}

.field-hint {
    font-size: 12px;
    &--error {
        color: $medispend-red;
    }

    &--warning {
        color: $medispend-orange;
    }

    &--info {
        color: $medispend-blue;
    }
}





