@import "../../../scss/variables/colors";
@import "./utils";

.ms-table-pagination-container {
    @extend .ms-table-control-type;

    position: relative;
    border-top: 1px solid $medispend-gray-200;
    padding-top: 16px;

    .btn {
        @extend .ms-table-control-type;
        background: transparent;
        border: none;
        border-radius: 5px;
        padding: 8px 12px;
        box-shadow: none !important;
        &:hover, &.active, &:active {
            background: $medispend-green;
            color: #FFFFFF;
        }
        &[disabled] {
            background: none;
            opacity: 0.5;
        }
        &.ms-table-prev-next {
            background: none;
        }
    }

    .ms-table-pagination-metadata,
    .ms-table-page-size {
        position: absolute;
    }
    .ms-table-pagination-metadata {
        top: 23px;
        left: 0;
    }
    .ms-table-page-size {
        top: 18px;
        right: 0;
    }
    .ms-table-pagination {
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width: 850px) {
    .ms-table-pagination-container {
        .ms-table-pagination-metadata,
        .ms-table-page-size {
            display: none;
        }
    }
}
