.ms-layout {
    .ms-layout-content-container {
        transition: left 0.2s ease-in-out;
        display: flex;
        align-items: flex-start;
        background: #FAFBFC;
        flex: 1;


        .ms-layout-content, .ms-layout-content-loader-container {
            width: 100%;
            min-height: 90vh;
            padding: 25px 25px 25px 25px;
            h1 {
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                margin-bottom: 8px;
            }
        }

        .ms-layout-content-loader-container {
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            min-height: 350px;
            background: rgba(255, 255, 255, 0.5);
            .ms-layout-content-loader {
                padding-top: 200px;
            }
        }

        .ms-layout-content-table, .ms-layout-content-inner {
            margin-top: 30px;
        }
    }

    &.ms-layout-has-side-nav {
        .ms-layout-content-container {
            padding-left: 250px;
            width: 100%;
            @media screen and (max-width: 767px) {
                padding-left: 68px;
            }
        }


    &.ms-layout-compact-view {
        .ms-layout-content-container {
            padding-left: 68px;
            width: 100%;
        }
    }
}
}

