@import "../../../scss/variables/colors";
@import "./utils";

.ms-table {
    &-filters-wrapper {
        display: flex;
        gap: 8px;
        align-items: flex-start;
        border-bottom: 1px solid $medispend-gray-200;
    }

    &-filters-buttons {
        padding-top: 16px;
        margin-left: auto;
    }

    .ms-table-filters-list {
        padding-bottom: 16px;
        padding-top: 16px;
        display: flex;
        flex: 1;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 10px;

        > * {
            flex-shrink: 0;
            margin-right: 8px;
        }

        > *:last-child {
            margin-right: 0px;
        }

        .ms-table-filter-item {
            .ms-multi-select-item {
                display: flex;
                align-items: center;
                input {
                    margin-right: 5px;
                }
            }
            .ms-table-filter-selected {
                @extend .ms-table-dropdown-selected;
            }
            .dropdown {
                @extend .ms-table-grey-dropdown;
                .dropdown-toggle {
                    display: flex;
                    align-items: center;
                    border-radius: 15px;
                    font-weight: 500;
                    span {
                        display: inline-block;
                        max-width: 150px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
            &:not(:first-child) {
                margin-left: 8px;
            }
        }
        .ms-table-filter-clear {
            margin-left: auto;
            cursor: pointer;
            color: $medispend-gray-500;
            span {
                margin-left: 5px;
                font-weight: 500;
            }
        }
    }
}
