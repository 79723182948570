@import "../../scss/variables/all";

.ms-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;

    &-title {
        font-size: 13px;
        line-height: 1;
        cursor: pointer;
        user-select: none;
        color: $medispend-gray-900;
    }

    &-input {
        appearance: none;
        margin-right: 10px;

        &::before {
            content: "";
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            background-color: $medispend-white;
            border: 1px solid $medispend-gray-500;
            border-radius: 5px;
            cursor: pointer;
        }

        &:checked::before {
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3203 2.99345C14.1471 2.99861 13.9827 3.071 13.862 3.19528L5.66668 11.3906L2.13804 7.86194C2.07661 7.79796 2.00303 7.74688 1.92161 7.71169C1.84019 7.6765 1.75257 7.65791 1.66387 7.65701C1.57518 7.65611 1.4872 7.67291 1.40508 7.70644C1.32296 7.73996 1.24836 7.78954 1.18564 7.85225C1.12292 7.91497 1.07334 7.98958 1.03982 8.07169C1.00629 8.15381 0.989489 8.2418 0.990391 8.33049C0.991293 8.41918 1.00988 8.5068 1.04507 8.58822C1.08026 8.66964 1.13135 8.74322 1.19533 8.80465L5.19533 12.8047C5.32036 12.9296 5.4899 12.9998 5.66668 12.9998C5.84346 12.9998 6.01301 12.9296 6.13804 12.8047L14.8047 4.13798C14.901 4.04434 14.9668 3.92378 14.9935 3.7921C15.0201 3.66042 15.0064 3.52377 14.954 3.40004C14.9016 3.27632 14.8131 3.1713 14.7 3.09875C14.587 3.02619 14.4546 2.98949 14.3203 2.99345Z' /%3E%3C/svg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
        }

        &:disabled::before {
            cursor: not-allowed;
        }

        &:disabled {
            opacity: 0.6;
        }
    }

    &-input.green:checked:before {
        background-color: $medispend-green;
        border-color: $medispend-green;
    }
}
