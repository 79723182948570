.ms-select {
    .Select-control {
        border-radius: 3px;
        height: 41px;

        .Select-input {
            height: 100%;
            width: 100%;
        }

        .Select-placeholder {
            line-height: 38px;
            padding: 0 12px;
            color: #999999;
        }
    }

    &.is-open {
        .Select-control {
            border-radius: 3px 3px 0 0;
        }
    }
}

.form-group {
    &.has-error {
        .Select-control {
            color: #a94442;
            background-color: #f2dede;
            border-color: #a94442;
        }

        .error {
            color: #a94442;
            text-align: left;
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 10px;
            line-height: 1;
        }
    }
}
