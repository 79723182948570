@import "../../../scss/utils/clearfix";

@import "./Breadcrumbs";
@import "./Content";
@import "./SideNav";
@import "./Header";

.ms-layout, .ms-layout-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    @extend .clearfix;
}

.ms-layout-container {
    padding-top: 63px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    background: $medispend-gray-50;
}
