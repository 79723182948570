.header-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    .header {
        color: $medispend-gray-900;
    }
}

.table-wrapper {
    .table-buttons {
        button + button {
            margin-left: 10px;
        }
    }
    .column-header {
        display: flex;
        align-items: center;
        font-weight: 500;
        cursor: pointer;

        span {
            margin-left: 5px;
        }
        .sort-order {
            font-family: Helvetica Neue,"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
        }
    }
}
