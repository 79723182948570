@import "../variables/all";

$loaders-path: "../../../media/loaders/";

.has-loading-fx {
}

.has-loading-fx:empty + .app-loading-fx {
    opacity: 0.6;
    z-index: 100;
    display: block;
}

.app-loading-fx {
    display: none;
    opacity: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #ffffff;
    z-index: -1;
    text-align: center;
    transition: opacity .8s ease-in-out;

    .ms-loading {
        background: url($loaders-path + "wedges.gif") center center no-repeat;
        width: 75px;
        height: 75px;
        position: absolute;
        top: 36%;
        display: inline-block;
        z-index: 99;
        margin-left: -44px;

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (moz-min-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
            background: url($loaders-path + "wedges@2x.gif") center center no-repeat;
            background-size: 75px auto;
        }
    }
}

.has-progress-fx {
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #ffffff url($loaders-path + "wedges.gif") center center no-repeat;
        opacity: 0.9;
        border-radius: 2px;

        @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (moz-min-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
            background-image: url($loaders-path + "wedges@2x.gif");
            background-size: 75px;
        }
    }
}
