@import "../../../scss/utils/clearfix";
@import "../../../scss/variables/colors";

.ms-text-search {
    float: left;
    width: 326px;
    height: 32px;

    .ms-sort-clear {
        color: #A1AFBA;
        display: flex;
        padding-left: 5px;
        align-items: center;
        padding-right: 5px;
        background: $medispend-white;
        cursor: pointer;
        margin-right: 2px;
        font-size: 13px;
    }

    .input-group {
        border-radius: 5px;
        border: 1px solid $medispend-gray-400;
        input {
            border: none;
        }
        button {
            border: none;
            border-radius: inherit;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
        }
    }

    .form-control {
        font-size: 13px;
        line-height: 16px;
        border-left: 0;
        padding: 0;
        height: 32px;

        &:focus {
            box-shadow: none;
            border: none;

        }
    }

    .input-group-text {
        background: $medispend-white;
        color: $medispend-gray-500;
        font-size: 13px;
        padding-bottom: 8px;
        border: 1px solid $medispend-gray-400;
    }
}

.ms-search-with-select {
    .ms-button.btn, .ms-search-title {
        border-bottom-right-radius: inherit;
        border-top-right-radius: inherit;
        border-right: 1px solid $medispend-gray-400;
        padding: 7px 16px;
        &:active {
            transform: none;
        }
    }
    .ms-search-title {
        color: $medispend-gray-900;
        background-color: $medispend-gray-200;
        font-weight: 500;
    }
    .input-group-text {
        border: none;
    }
    .form-control {
        border: none;
    }

}
