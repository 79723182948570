@import "../../../../../../../../lib/common/src/scss/variables/all";


.additional-input-container {
    display: flex;
    padding-top: 29px;
    padding-left: 10px;
    .ms-select .dropdown {
        .ms-select-button.ms-button.btn-white {
            width: 151px;
        }
    }
    .ms-select-button-custom {
        height: 40px;
        padding-top: 0;
    }
    .additional-input-item {
        display: flex;
        gap: 5px;
        align-items: center;
        border-radius: 15px;
        background-color: $medispend-gray-100;
        padding: 8px 16px;
        font-size: 13px;
        line-height: 16px;
        height: 34px;
        margin-left: 8px;
        .fa-times {
            cursor: pointer;
            color: $medispend-gray-700;
        }
    }
}
.disabled-button {
    cursor: default;
    .ms-icon {
        color: $medispend-gray-500;
    }
}
