@import "../utils/all";

.entity-page {
    @include main-area();
    padding: 0 24px 24px 24px;
    &--padding-top {
        padding-top: 24px;
    }
    .buttons-wrapper {
        display: flex;
        justify-content: center;
        button:nth-child(odd) {
            margin-right: 8px;
        }
    }
    .section-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $medispend-gray-900;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .value {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: $medispend-gray-900;
    }
    .ms-form-group {
        .form-label {
            color: $medispend-gray-500;
            margin-bottom: 4px;
            display: block;
            .button-icon {
                position: relative;
                right: 2px;
                top: 2px;
            }
        }
    }
    .entity-section {
        padding-top: 24px;
        padding-bottom: 8px;
        border-bottom: 1px solid $medispend-gray-200;
    }
    .entity-section:last-of-type {
        border-bottom: none;
    }

    .sub-screen-name {
        font-size: 24px;
        font-weight: 500;
        margin-top: 34px;
        margin-bottom: 38px;
    }
}

.additional-entities-table {
    .buttons-wrapper {
        display: flex;
        justify-content: center;

        button:nth-child(odd) {
            margin-right: 8px;
        }
    }
}

.edit-mode {
    .ms-form-group {
        .form-label {
            color: $medispend-gray-900;
        }
    }
}

.page-wrapper {
    max-width: 1324px;
    margin: 0 auto;
    #process-name {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        span {
            margin-left: 5px;
        }
        .process-status {
            font-size: 14px;
            font-weight: 500;
            text-decoration: none;
            padding: 2px 12px;
            border-radius: 12px;
            display: flex;
            height: 24px;
            align-items: center;
            margin-left: 16px;
        }
    }
    .screen-name {
        font-size: 20px;
        text-decoration: underline;
        margin-bottom: 20px;
    }

    .open-status-label {
        color: $ms-badge-text-grey;
        background-color: $ms-badge-background-grey;
    }
    .inReview-status-label {
        color: $ms-badge-text-yellow;
        background-color: $ms-badge-background-yellow;
    }
    .conditionallyApproved-status-label {
        color: $ms-badge-text-blue;
        background-color: $ms-badge-background-blue;
    }

    .approved-status-label {
        color: $ms-badge-text-green;
        background-color: $ms-badge-background-green;
    }

    .closed-status-label {
        color: $ms-badge-text-red;
        background-color: $ms-badge-background-red;
    }
}
