@import "../../../scss/components/form";
@import "../../../scss/utils/formInput";

.ms-form-group {
    .ms-input {
        @extend .form-input;
    }
    .ms-input:focus {
        outline: none;
        border: 1px solid $medispend-green;
    }
    .ms-input.has-error {
        border: 1px solid $medispend-red;
    }
}
