@import "../../../../../../../lib/common/src/scss/variables/all";

        .create-rule-form-container {
            display: flex;
            justify-content: center;
            padding-top: 16px;

            .create-rule-form {
                width: 640px;
                height: 100%;

                .business-rule-type {
                    margin-bottom: 40px;
                }

                h3 {
                    font-size: 18px;
                }
            }
            .business-rule-type, .business-rule-client {
                width: 240px;
                .ms-select {
                    .dropdown {
                        .ms-select-button, .dropdown-menu {
                            max-width: 240px
                        }
                    }
                }
            }

            .field-group-container {
                display: flex;
                gap: 16px;
                margin-top: 16px;

                .business-rule-application, .business-rule-process {
                    width: 240px;
                    .ms-select {
                        .dropdown {
                            .ms-select-button, .dropdown-menu {
                                width: 240px
                            }
                        }
                    }
                }
            }
        }
    .button-group {
        display: flex;
        justify-content: right;
        gap: 16px;
        margin-top: 16px;
        margin-bottom: 30px;
    }
