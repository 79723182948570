// MediSpend Utils
$medispend-white: #FFFFFF;
$medispend-gray-50: #FAFBFC;
$medispend-gray-100: #F3F5F7;
$medispend-gray-200: #EDF0F2;
$medispend-gray-300: #F8F9FA;
$medispend-gray-400: #D8E2E8;
$medispend-gray-500: #A1AFBA;
$medispend-gray-550: #7C9CB3;
$medispend-gray-600: #5A6872;
$medispend-gray-700: #456077;
$medispend-gray-800: #363F45;
$medispend-gray-900: #192024;

$medispend-yellow-200: #FEF3C7;
$medispend-yellow-800: #92400E;

// MediSpend Base Colors
$medispend-green: #00AA74; // #00B86E;
$medispend-light-green: #DBF9EF;
$medispend-slate: #333F48;
$medispend-tangerine: #FFBF3F;
$medispend-grey: #898D8D;
$medispend-indigo: #3F2A56;
$medispend-iris: #614B79;
$medispend-orange: #ED8532; // #ED7D23;
$medispend-dark-orange: #FB8249;
$medispend-red: #E30000;
$medispend-skyblue: #00B5E2;
$medispend-dark-blue: #2799C9;
$medispend-blue: #007DBA;
$medispend-steel: #4E87A0;
$medispend-turquoise: #00966C;
$medispend-forest: #046A38;
$medispend-denim: #01426A;
$medispend-lime: #83c441;
$medispend-light-lime: #8dd446;
$medispend-yellow-dark: #EDB932;
$medispend-red-dark: #D84444;;

// MediSpend Product Colors
$medispend-insights: $medispend-indigo;
$medispend-france: $medispend-iris;
$medispend-em: $medispend-orange;
$medispend-lite: $medispend-skyblue;
$medispend-federal: $medispend-blue;
$medispend-validate: $medispend-steel;
$medispend-elite: $medispend-turquoise;
$medispend-notification: $medispend-forest;
$medispend-fair-market-value: $medispend-denim;
$medispend-grants: $medispend-lime;

// MediSpend UI Supplemental Colors
$medispend-blue-border: #CCEFF7;
$medispend-blue-bg: #E8F8FC;
$medispend-grey-border: #E8E8E8;
$medispend-grey-bg: #F9FAFC;

// MediSpend UI Web Colors
$ms-colors-prim: $medispend-green;
$ms-colors-prim-bg: rgba(0, 170, 116, 0.85); // rgba(0, 184, 110, 0.85);
$ms-colors-sec: $medispend-orange; //#e77000;
$ms-colors-sec-bg: rgba(237, 133, 50, 0.85); // rgba(231, 112, 0, 0.85);

// Medispend UI Web Secondary Navigation Area
$ms-nav-sec-bg: #2e323a;
$ms-nav-sec-color: rgba(225, 225, 225, 0.5);
$ms-nav-sec-hover: #FFFFFF;

// MediSpend Notifications Colors
$ms-notification-success: rgb(240, 245, 234);
$ms-notification-warning: rgb(249, 246, 240);
$ms-notification-error: rgb(244, 233, 233);

//Medispend Badges Colors
$ms-badge-background-red: #FEE2E2;
$ms-badge-background-green: #D1FAE5;
$ms-badge-background-yellow: #FEF3C7;
$ms-badge-background-blue: #DBEAFE;
$ms-badge-background-grey: #F3F4F6;

$ms-badge-text-red: #991B1B;
$ms-badge-text-green: #065F46;
$ms-badge-text-yellow: #92400E;
$ms-badge-text-blue: #1E40AF;
$ms-badge-text-grey: #1F2937;;

@mixin hover-green() {
    color: #ffffff;
    border-color: $ms-colors-prim;
    background: $ms-colors-prim-bg;
}

@mixin hover-orange() {
    color: #ffffff;
    border-color: $ms-colors-sec;
    background: $ms-colors-sec-bg;
}

@mixin hover-grey() {
    color: #333333;
    border-color: #dddddd;
    background: #eeeeee;
}

@mixin hover-primary() {
    @include hover-orange();
}

@mixin hover-secondary() {
    @include hover-grey();
}
