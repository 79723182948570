.ms-loading-indicator-container {
    width: 100%;
    height: 100%;
    padding: 30px;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 1060;
    opacity: 0.7;

  .ms-loading-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
  }
}
