@import "../../../../../../../lib/common/src/scss/variables/all";

.create-rule-container {
    background: $medispend-white;
    border: 1px solid $medispend-gray-100;
    border-radius: 5px;
    padding-top: 50px;
    padding-right: 65px;
    padding-left: 65px;
    .form-container {
        border: 1px solid $medispend-gray-400;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .create-rule-header {
            display: flex;
            align-items: center;
            background: $medispend-gray-400;
            height: 44px;
            padding-left: 20px;

            h2 {
                font-size: 20px;
                margin: 0;
            }
        }
        h4 {
            font-size: 14px;
        }
    }
}
