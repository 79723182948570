@import "../../lib/common/src/scss/MediSpend";
@import "../../lib/common/src/scss/variables/colors";

body {
    background: #ffffff;
}

h1 {
    color: $medispend-green;
}
