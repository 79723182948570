.pagination {
  li {
    a {
      &:hover, &:active {
        @include hover-secondary();
      }
    }
    &:first-child {
      a, span {
        border-radius: 3px 0 0 3px;
      }
    }
    &:last-child {
      a, span {
        border-radius: 0 3px 3px 0;
      }
    }
  }
}