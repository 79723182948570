.btn {
    border-radius: 5px;
    padding: 8px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    border: none;

    &.btn-success {
        background: $medispend-green;
        color: white;
    }

    &.btn-warning {
        background: $medispend-yellow-dark;
        color: white;
    }

    &.btn-danger {
        background: $medispend-red-dark;
        color: white;
    }

    &.btn-secondary {
        background: $medispend-gray-100;
        color: $medispend-gray-900;
    }

    &.btn-workflow {
        background: $medispend-orange;
        color: white;
        padding: 6px 8px;
    }

    &.btn-default {
        border-color: rgba(0, 0, 0, 0.1);
        &:focus, &:active {
            outline: none;
        }

        &.btn-ms {
            &:hover, :focus, &:active {
                @include hover-secondary();
            }
        }
        &.btn-ms-primary {
            @include hover-primary();
            &:hover, :focus, :active {
                @include hover-primary();
            }
        }
        &.btn-ms-large {
            padding: 10px 15px;
        }
        &.btn-ms-fullwidth {
            width: 100%;
        }
    }
}

.btn-close {
    opacity: 1;
}
