@import "../../../scss/variables/all";
@import "../../../scss/utils/formInput";

.form-label {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $medispend-gray-900;
    .required-sign {
        color: $medispend-red;
        margin-left: 2px;
        font-size: 13px;
        line-height: 16px;
    }
    &.has-error {
        color: $medispend-red;
        font-size: 14px;
    }
}
.has-error.ms-select-button {
    border: 1px solid $medispend-red;
}
.MyDatePicker {
    position: relative;
    .ms-datepicker-button {
        width: 100%;
    }
    .ms-select-button {
        width: 100%;
    }
    .datepicker-title {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        color: $medispend-gray-500;
    }
    .ms-datepicker {
        float: left;
        position: absolute;
        left: auto;
        top: 40px;
        right: auto;
        width: 330px;
        min-height: 350px;
        background: $medispend-white;
        box-shadow: 0 4px 15px rgb(0 0 0 / 15%);
        border-radius: 5px;
        padding: 20px 15px 5px 15px;
        z-index: 2;
        .header-container {
            display: flex;
            justify-content: space-between;
            align-content: center;
            margin-left: 10px;
            margin-right: 10px;
            .middle-buttons-container {
                display: flex;
                column-gap: 5px;
            }
            .button-content {
                height: 35px;
                padding-top: 8px;
                cursor: pointer;
            }
        }
    }


}
.MyDatePicker * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.ms-datepicker-body{
    float: left;
    width: 100%;
}

.calendar-head,
.calendar-name,
.calendar-body,
.calendar-day span,
.calendar-day,
.calendar-day-container,
.calendar-container{
    position: relative;
    display: block;
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.calendar-container{
    width: 100%;
    height: 100%;
    .calendar-head{
        height: 30px;
        width: 100%;
        margin-top: 10px;
        .calendar-name{
            width: 14.285%;
            height: 30px;
            line-height: 30px;
            font-weight: 700;
            color: $medispend-gray-900;
            font-size: 12px;
            text-align: center;
        }
    }
    .calendar-body{
        height: 270px;
        width: 100%;
        .calendar-day-container{
            width: 14.285%;
            height: 16.6666%;
            &.disabled {
                pointer-events: none;
                .calendar-day span {
                    color: $medispend-gray-500;
                    background: $medispend-white!important;
                }
            }
            &.future, &.past {
                .calendar-day span {
                    color: $medispend-gray-500;
                    background: $medispend-white;
                }
            }
            &.highlight {
                .calendar-day span {
                    background: $medispend-gray-200;
                }
            }
            &.highlight-green {
                .calendar-day span {
                    background: $medispend-green;
                }
            }
            .calendar-day{
                width: 100%;
                height: 100%;
                font-size: 12px;
                font-weight: 900;
                color: $medispend-gray-900;
                text-align: center;
                span{
                    width: 30px;
                    height: 30px;
                    margin-top: -15px;
                    margin-left: -15px;
                    left: 50%;
                    top: 50%;
                    font-weight: 700;
                    color: $medispend-gray-900;
                    border-radius: 100%;
                    line-height: 30px;
                    &:hover {
                        cursor: pointer;
                        background: #eee;
                    }
                }
            }
        }
    }
}

.ms-offset {
    top: -375px !important;
}
