@import "../../../scss/variables/colors";
@import "../../../scss/utils/clearfix";

@mixin breadcrumb-active-link {
    color: $medispend-green;
    font-weight: 500;
}

.ms-layout-breadcrumbs {
    @extend .clearfix;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    display: inline-block;

    li {
        float: left;

        &.divider {
            margin: 0 5px;
        }

        a {
            font-size: 12px;
            line-height: 14px;
            color: $medispend-gray-900;

            &:hover {
                @include breadcrumb-active-link();
            }
        }

        &.active {
            a {
                @include breadcrumb-active-link();
            }
        }
    }
}
