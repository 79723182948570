@import "../../../../../../../../lib/common/src/scss/variables/all";


.cell {
    border: 2px solid $medispend-gray-500;

    .add-button {
        transition: all 0.2s ease;
        position: absolute;
        font-size: 24px;
        right: -14px;
        z-index: 100;
        background-color: $medispend-white;
        line-height: 28px;
        cursor: pointer;
        color: $medispend-gray-700;
        top: 35%;
        &:hover {
            color: $medispend-gray-500;
        }
    }

    .cell-container {
        display: flex;
        flex-direction: column;
        padding: 11px 25px 8px 16px;
        min-width: 291px;
        height: 100%;
        position: relative;
        .ellipsis-dropdown, .ellipsis-dropdown.show {
            position: absolute;
            right: 3px;
            top: 3px;
            &.dropdown {
                button {
                    background-color: $medispend-white;
                    margin: 0;
                    padding: 0;
                    color: $medispend-gray-700;

                    &:hover, &:focus {
                        background-color: $medispend-white;
                        color: $medispend-gray-500;
                        box-shadow: none;
                    }
                    &::after {
                        display: none;
                    }
                    .row-options {
                        cursor: pointer;
                    }
                }
                .dropdown-menu.show {
                    transform: translate3d(-156px, 18px, 0px) !important;
                }
            }
        }

        .cell-condition {
            font-size: 20px;
            font-weight: 400;
            min-height: 30px;
        }

        .cell-name {
            display: flex;
            justify-content: center;
            white-space: nowrap;
            font-size: 27px;
            color: $medispend-gray-400;
            height: 100%;
            align-items: center;
            &.highlight {
                color: $medispend-gray-700;
            }
        }

        .cell-type {
            display: flex;
            justify-content: flex-end;
            font-size: 16px;
            font-weight: 400;
            min-height: 24px;
        }
    }
}

.full-width {
    min-width: 295px;
    width: 100%;

    .cell-container {
        width: 100%;
    }
}

.invalid-field {
    color: $medispend-red-dark;
        .cell-container {
            .remove-button {
                color: $medispend-red-dark;
                &:hover {
                    color: $medispend-red-dark;
                }
            }
            .cell-name.highlight, .cell-name {
                color: $medispend-red-dark
            }
        }
}

.dropdown-wrapper {
    .dropdown {
        max-height: 0;
    }
    .dropdown-toggle {
        visibility: hidden;
        max-height: 0;
    }
    .dropdown-input-custom.dropdown-menu.show, .dropdown-add-input-custom.dropdown-menu.show {
        max-height: 300px;
        overflow: scroll;
    }

    .dropdown-input-custom {
        margin-left: 50px;
    }
    .dropdown-add-input-custom {
        margin-left: 190px;
    }
}
