@import "../../variables/all";

$dropdown-hover-color: $ms-colors-sec-bg;

@mixin dropdown-menu-arrow {
    &:before, &:after {
        left: auto;
        position: absolute;
        display: inline-block;
        content: "";
    }
    &:before {
        border: 8px solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.15);
    }
    &:after {
        border: 7px solid transparent;
        border-bottom-color: #fff;
    }
}

.dropdown-menu {
    padding: 10px 0;
    font-size: 13px;
    color: $medispend-gray-900;
    border: 0;
    background: #FFFFFF;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    .dropdown-item {
        padding: 8px 30px;
        &:active, &.active {
            background: $medispend-gray-200;
            color: $medispend-gray-900;
        }
    }
    .divider {
        margin: 5px 0;
    }

    li {
        a {
            padding: 8px 20px;
            cursor: pointer;

            &:hover {
                background: $dropdown-hover-color;
                color: #ffffff;
            }

            .icon {
                padding-right: 8px;
            }
        }
    }

    &.dropdown-menu-sw {
        @include dropdown-menu-arrow();
        margin-top: 8px;

        &:before {
            top: -16px;
            right: 9px;
        }

        &:after {
            top: -13px;
            right: 10px;
        }
    }

    &.dropdown-menu-se {
        @include dropdown-menu-arrow();
        margin-top: 8px;

        &:before {
            top: -16px;
            left: 9px;
        }

        &:after {
            top: -13px;
            left: 10px;
        }
    }
}


.btn-default:active:focus,
.open > .btn-default.dropdown-toggle:focus,
.btn-group.open .dropdown-toggle {
    @include hover-primary();
}

.dropdown-toggle {
    margin-bottom: 6px;
    padding: 8px 16px;
    &.btn-default {
        border-color: #dddddd;
    }
    &::after {
        border-bottom: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid;
        margin-left: 12px;
        display: inline-block;
        vertical-align: 2px;
        content: "";
    }
}

.dropdown {
    .dropdown-toggle {
        border-radius: 5px;
    }
    .dropdown-toggle::after {
        align-self: center;
        margin-left: 8px;
    }
    .dropdown-menu {
        .dropdown-item {
            padding: 8px 30px;
        }
    }
}
